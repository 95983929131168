// ChatComponent.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
  Typography,
  Paper,
  InputBase,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';

const TypingAnimation = () => {
  const typingKeyframes = keyframes`
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
  `;

  return (
    <Box display="flex" alignItems="center">
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          sx={{
            width: 8,
            height: 8,
            backgroundColor: 'gray',
            borderRadius: '50%',
            marginRight: 1,
            animation: `${typingKeyframes} 1.4s infinite ${i * 0.2}s`,
          }}
        />
      ))}
    </Box>
  );
};

// Styled components for user and AI messages
const UserMessage = styled(Paper)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  borderRadius: '15px',
  padding: theme.spacing(1),
  maxWidth: '80%',
  alignSelf: 'flex-end',
  marginBottom: theme.spacing(1),
}));

const AIMessage = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  borderRadius: '15px',
  padding: theme.spacing(1),
  maxWidth: '80%',
  alignSelf: 'flex-start',
  marginBottom: theme.spacing(1),
}));

const ChatComponent = () => {
  // State to manage messages for the chat UI
  const [messages, setMessages] = useState([]);
  // State to manage conversation history for OpenAI API
  const [conversation, setConversation] = useState([]);
  const [modelName, setModelName] = useState('grok-2-1212');
  
  const [assitanceValue, setAssitanceValue] = useState(
    `
    אתה יועץ משפטי מומחה המתמחה בפסקי דין ישראליים. תפקידך לספק ניתוח מעמיק ומדויק של החלטות בית המשפט, תוך התייחסות לפסיקות קודמות ולחוקים הרלוונטיים. בכל תשובה, עליך:

    1. לזהות את הסוגיה המשפטית המרכזית בשאלה.
    2. לספק רקע קצר על החוק או התקדים הרלוונטי.
    3. לציין לפחות פסק דין אחד רלוונטי, כולל מספר התיק (למשל, בג"ץ 1234/56)  - רק אם מדובר בפסקי דין המקושרים באופן ישיר לשאלת המשתמש! אם לא מצאת פסקי דין רלוונטיים דלג שלב זה.
    4. לפרק את הניתוח לשלבים ברורים, תוך הסבר של כל שלב בתהליך המשפטי.
    5. להציג את הנימוקים העיקריים של בית המשפט בפסיקה הרלוונטית.
    6. לעודד חשיבה ביקורתית על ידי הצגת טיעונים אפשריים מכל הצדדים.
    7. להסביר את ההשלכות האפשריות של הפסיקה על מקרים עתידיים.
    8. לספק הפניות לסעיפי חוק ספציפיים כאשר רלוונטי.
    9. להימנע מלתת ייעוץ משפטי ישיר, אלא להתמקד בהסבר והבהרה של החוק והפסיקה.

    זכור תמיד להיות אובייקטיבי ומדויק, ולהדגיש את מורכבות המערכת המשפטית הישראלית. עודד את המשתמש לחשוב באופן ביקורתי על הסוגיות המשפטיות ולהבין את ההקשר הרחב של כל החלטה משפטית.
    `
  );
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Initialize conversation with assistant prompt
    const initialAssistantMessage = { role: 'assistant', content: assitanceValue };
    setConversation([initialAssistantMessage]);
  }, [assitanceValue]);

  useEffect(() => {
    // Scroll to the bottom when messages change
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSend = async (messageText) => {
    if (modelName.includes("grok")) {
      await handleGrokSend(messageText)
      return
    }
    // if (modelName === "llama-3.1-sonar-huge") {
    //   perplexitySend(messageText)
    //   return
  // }
  
    // if (!messageText.trim()) return; // Prevent sending empty messages

    // // Create the new user message object
    // const userMessageForAPI = { role: 'user', content: messageText };

    // // Update the conversation state with the new user message
    // const updatedConversation = [...conversation, userMessageForAPI];
    // setConversation(updatedConversation);

    // // Update the messages state with the new user message
    // setMessages((prev) => [...prev, { type: 'user', text: messageText }]);

    // // Set loading state to true
    // setIsLoading(true);

    // try {
    //   // Call OpenAI API with the updated conversation history
    //   const response = await fetch('https://api.openai.com/v1/chat/completions', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    //     },
    //     body: JSON.stringify({
    //       model: modelName,
    //       messages: updatedConversation,
    //     }),
    //   });

    //   const data = await response.json();

    //   // Set loading state to false
    //   setIsLoading(false);

    //   // Handle possible API errors
    //   if (response.ok) {
    //     const aiReply = data.choices[0].message.content;

    //     // Append AI's response to the chat UI
    //     setMessages((prev) => [...prev, { type: 'ai', text: aiReply }]);

    //     // Append AI's response to the conversation history
    //     const aiMessageForAPI = { role: 'assistant', content: aiReply };
    //     setConversation((prev) => [...prev, aiMessageForAPI]);
    //   } else {
    //     console.error('OpenAI API error:', data);
    //     // Optionally, display an error message to the user
    //     const errorText = 'Sorry, I encountered an error. Please try again later.';
    //     setMessages((prev) => [...prev, { type: 'ai', text: errorText }]);
    //   }
    // } catch (error) {
    //   // Set loading state to false
    //   setIsLoading(false);

    //   console.error('Network error:', error);
    //   // Optionally, display an error message to the user
    //   const errorText = 'Sorry, I encountered a network error. Please check your connection.';
    //   setMessages((prev) => [...prev, { type: 'ai', text: errorText }]);
    // }
  };

  const perplexitySend = async (message) => {
    const apiKey = '';

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: "llama-3.1-sonar-huge-128k-online", // Specify the model you wish to use
        messages: [
          {
            role: "user",
            content: message,
          },
          {
            role: "assistant",
            content: assitanceValue,
          },
          {
            role: "user",
            content: message,
          },
        ],
        temperature: 0,
        search_domain_filter: [
          "https://www.takdin.co.il",
          "https://www.lawdata.co.il",
          "https://www.nadlancenter.co.il"
        ],
      }),
    };
    
    fetch('https://api.perplexity.ai/chat/completions', options)
      .then(response => {
        if (!response.ok) {
          // Parse the error response
          return response.json().then(errorData => {
            // Handle specific error based on the response
            console.error('Error Details:', errorData);
            // Example: Accessing error message
            if (errorData.error && errorData.error.message) {
              console.error('Error Message:', errorData.error.message);
            }
            // Throwing an error to be caught in the catch block
            throw new Error('API request failed');
          });
        }
        return response.json();
      })
      .then(data => {
        const assistantMessage = data.choices[0].message.content;
        console.log('Assistant:', assistantMessage);
      })
      .catch(error => {
        console.error('An error occurred:', error.message);
      });
  }

  const handleGrokSend = async (messageText) => {
    if (!messageText.trim()) return; // Prevent sending empty messages

    // Create the new user message object
    const userMessageForAPI = { role: 'user', content: messageText };

    // Update the conversation state with the new user message
    const updatedConversation = [...conversation, userMessageForAPI];
    setConversation(updatedConversation);

    // Update the messages state with the new user message
    setMessages((prev) => [...prev, { type: 'user', text: messageText }]);

    // Set loading state to true
    setIsLoading(true);

    try {
      // Call OpenAI API with the updated conversation history
      const response = await fetch('https://api.x.ai/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_X_API_KEY}`,
        },
        body: JSON.stringify({
          model: modelName,
          messages: updatedConversation,
        }),
      });

      const data = await response.json();

      // Set loading state to false
      setIsLoading(false);

      // Handle possible API errors
      if (response.ok) {
        const aiReply = data.choices[0].message.content;

        // Append AI's response to the chat UI
        setMessages((prev) => [...prev, { type: 'ai', text: aiReply }]);

        // Append AI's response to the conversation history
        const aiMessageForAPI = { role: 'assistant', content: aiReply };
        setConversation((prev) => [...prev, aiMessageForAPI]);
      } else {
        console.error('OpenAI API error:', data);
        // Optionally, display an error message to the user
        const errorText = 'Sorry, I encountered an error. Please try again later.';
        setMessages((prev) => [...prev, { type: 'ai', text: errorText }]);
      }
    } catch (error) {
      // Set loading state to false
      setIsLoading(false);

      console.error('Network error:', error);
      // Optionally, display an error message to the user
      const errorText = 'Sorry, I encountered a network error. Please check your connection.';
      setMessages((prev) => [...prev, { type: 'ai', text: errorText }]);
    }
  };

  return (
    <Box display="flex" width="100%" height="100%" overflow="hidden">
      {/* Chat Section - 70% */}
      <Box
        width="70%"
        display="flex"
        flexDirection="column"
        sx={{ position: 'relative' }}
      >
        {/* Messages area */}
        <Box
          flex={1}
          overflow="auto"
          padding={2}
          sx={{ backgroundColor: '#fafafa', marginBottom: '80px' }} // Adjust marginBottom to match input area height
        >
          {messages.map((msg, index) => (
            <Box key={index} display="flex" flexDirection="column">
              {msg.type === 'user' ? (
                <UserMessage>
                  <Typography>{msg.text}</Typography>
                </UserMessage>
              ) : (
                <AIMessage>
                  <ReactMarkdown>{msg.text}</ReactMarkdown>
                </AIMessage>
              )}
            </Box>
          ))}
          {isLoading && (
            <Box display="flex" justifyContent="center" alignItems="center" marginY={1}>
              <TypingAnimation />
            </Box>
          )}
          {/* Dummy element to maintain scroll position */}
          <div ref={messagesEndRef} />
        </Box>
        {/* Input area fixed at the bottom */}
        <Box
          sx={{
            padding: '10px',
            backgroundColor: '#ffffff',
            borderTop: '1px solid #ddd',
            position: 'fixed',
            bottom: 0,
            width: '60%', // Adjust to match the chat section width
          }}
        >
          <Paper
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const input = e.target.elements.message;
              handleSend(input.value);
              input.value = '';
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '25px',
              border: '1px solid #ccc',
              padding: '5px 15px',
            }}
          >
            <InputBase
              name="message"
              placeholder="שאל כל שאלה בנוגע לפסקי דין"
              sx={{ ml: 1, flex: 1 }}
              onKeyPress={(e) => {
                if (e.shiftKey && e.charCode === 13) {
                  return;
                }
                if (e.charCode === 13 && !e.shiftKey) {
                  e.preventDefault();
                  handleSend(e.target.value);
                  e.target.value = '';
                }
              }}
            />
            <IconButton type="submit" color="primary">
              <SendIcon />
            </IconButton>
          </Paper>
        </Box>
      </Box>

      {/* Control Section - 30% */}
      <Box
        width="30%"
        height="100%"
        padding={2}
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        gap={4}
        sx={{ overflowY: 'auto' }}
      >
        {/* Model Selection */}
        <FormControl fullWidth>
          <InputLabel id="model-select-label">Select Model</InputLabel>
          <Select
            labelId="model-select-label"
            value={modelName}
            label="Select Model"
            onChange={(e) => setModelName(e.target.value)}
          >
            {/* <MenuItem value="o1-preview">o1-preview</MenuItem>
            <MenuItem value="o1-mini">o1-mini</MenuItem>
            <MenuItem value="gpt-4o">gpt-4o</MenuItem> */}
            {/* <MenuItem value="llama-3.1-sonar-huge">Perplexity: llama-3.1-sonar-huge</MenuItem> */}
            <MenuItem value="grok-2-1212">X: grok-2-1212</MenuItem>
            <MenuItem value="grok-2">X: grok-2</MenuItem>
            <MenuItem value="grok-2-latest">X: grok-2-latest</MenuItem>
            {/* Add more models as needed */}
          </Select>
        </FormControl>

        {/* Assistance Value Editor */}
        <FormControl fullWidth>
          <TextField
            label="Assistance Value"
            multiline
            minRows={4}
            value={assitanceValue}
            onChange={(e) => setAssitanceValue(e.target.value)}
            variant="outlined"
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default ChatComponent;
