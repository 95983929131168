import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import { useForm } from "./FormContext";
import { useContracts } from '../Docx/ContractsContext';

import useApi from '../useApi';
import { useDeals } from "../DealsContext"
import VersatileAlertDialog from "../UI/VersatileAlertDialog"
import LoaderComponent, { LoadingState }  from '../Common/LoaderComponent';

const FormSummaryGeneration = ({ isVisible }) => {
  const [dealNameValue, setDealNameValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [versatileAlertOpen, setVersatileAlertOpen] = useState(false);

  const { formDataArray, selectedFiles } = useForm();
  const { useGenerateForms } = useApi();
  const setGenerateForms = useGenerateForms();

  const { refreshDealsData } = useDeals();

  const navigate = useNavigate();
  const location = useLocation();
  
  const { contracts, govContracts } = useContracts();

  useEffect(() => {
    if (!dealNameValue && isVisible) {
      setDealNameValue(getDefaultName());
    }
  }, [isVisible]);

  const getDefaultName = () => {
    let fileName = "";
    formDataArray().forEach((formBlock) => {
      formBlock.inputs.forEach((item) => {
        if (item.user_input_data) {
          if (
            item.placeholder === "שם_פרטי_קונה_1" ||
            item.placeholder === "שם_פרטי_מוכר_1"
          ) {
            fileName += fileName
              ? ` את ${item.user_input_data}`
              : item.user_input_data;
          }
        }
      });
    });
    return !fileName ? "עיסקה ללא שם" : fileName;
  };

  const handleGenerateForms = async () => {
    if (selectedFiles.length === 0) {
      setAlertMessage("נראה ששכחת לבחור קבצי בסיס. בחר קבצי בסיס ונסה שוב, זה יעבוד :)");
      setAlertVisible(true);
      return;
    }
    setIsLoading(true);

    const dealName = !dealNameValue ? getDefaultName() : dealNameValue;
    try {
      const actualFileNames = selectedFiles.map(file => {
        const contract = contracts.find(c => c.name.includes(file));
        // Check if contract is valid before accessing file_url
        if (contract && contract.file_url) {
          const fileParts = contract.file_url.split('/').pop().split('.');
          if (fileParts.length > 1) {
            fileParts.pop(); // Remove the extension
          }
          return fileParts.join('.'); // Rejoin the remaining parts of the name
        }
        return null;  // Invalid contracts
      }).filter(Boolean); // Remove null or undefined entries
      
      const actualGovFileNames = selectedFiles.map(file => {
        const contract = govContracts.find(c => c.name.includes(file));
        if (contract && contract.file_url) {
          const fileParts = contract.file_url.split('/').pop().split('.');
          if (fileParts.length > 1) {
            fileParts.pop();
          }
          return fileParts.join('.');
        }
        return null;
      }).filter(Boolean);
      
      
      const encodedSelectedFiles = encodeFileNames(actualFileNames);
      const encodedSelectedFilesByContractName = encodeFileNames(selectedFiles);
      const encodedSelectedGovFiles = encodeFileNames(actualGovFileNames);

      const response = await setGenerateForms(
        formDataArray(),
        encodedSelectedGovFiles,
        encodedSelectedFilesByContractName,
        encodedSelectedFiles,
        dealName,
        location.state ? location.state.deal.id : null,
      );
      console.log("handleGenerateForms successful: ", response);

      await refreshDealsData();
      navigate('/dashboard/reports');
    } catch (error) {
      console.error("handleGenerateForms failed: ", error);
      setVersatileAlertOpen(true)
    } finally {
      setIsLoading(false);
    }
  };

  const encodeFileNames = (fileNames) => {
    return fileNames.map(fileName => btoa(encodeURIComponent(fileName)));
  };

  const renderInputs = (block) => {
    const maxWidth =
      Math.max(...block.inputs.map((input) => measureText(input.label))) + 5;

    return (
      <div>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          {block.title}
        </Typography>

        {block.inputs.map((input, index) =>
          input.user_input_data ? ( // Only render if user_input_data is not empty
            <div
              key={index}
              style={{ display: "flex", alignItems: "center", margin: "8px 0" }}
            >
              <Typography
                variant="body1"
                style={{
                  minWidth: `${maxWidth}px`,
                }}
              >
                {input.label}
              </Typography>
              <Typography style={{ marginRight: "8px" }}>:</Typography>
              <Typography variant="body1">{input.user_input_data}</Typography>
            </div>
          ) : null
        )}
      </div>
    );
  };  

  const measureText = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '16px Arial';
    return context.measureText(text).width;
  };

  const renderBlocks = () => {
    const data = formDataArray();
    return data.map((block, index) => (
      <Grid item xs={12} sm={6} lg={4} key={index}>
        <Card
          variant="elevation"
          sx={{
            margin: '8px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '20px', // Increased border radius for bubble effect
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Soft shadow
            transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transitions
            background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)', // Optional gradient
            '&:hover': {
              transform: 'translateY(-10px) scale(1.02)', // Slight lift and scale on hover
              boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
            },
            '&:active': {
              transform: 'translateY(-5px) scale(1.01)', // Slight adjustment on click
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)', // Reduced shadow on active
            },
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <div style={{ borderBottom: '1px solid #eee', paddingBottom: '8px', marginBottom: '8px' }}>
              {renderInputs(block)}
            </div>
          </CardContent>
        </Card>
      </Grid>
    ));
  };
  

  const dealNameValueChanged = (event) => {
    setDealNameValue(event.target.value);
  };

  const handleVersatileAlertClose = () => {
    setVersatileAlertOpen(false);
  };

  return (
    <div style={{ direction: "rtl" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "20px",
          marginRight: "-240px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateForms}
          style={{
            width: "300px",
            marginBottom: "10px",
          }}
        >
          צור מסמכים לעיסקה
        </Button>
        <TextField
          label={"שם העיסקה"}
          variant="filled"
          value={dealNameValue}
          onChange={dealNameValueChanged}
          sx={{
            width: "300px",
          }}
        />
      </div>
      <Grid container spacing={2} alignItems="stretch">
        {renderBlocks()}
      </Grid>

      <LoaderComponent
        loading={isLoading}
        loadingState={LoadingState.FORMS_GENERATION}
      />

      {alertVisible && (
        <Alert
          style={{
            marginTop: 12,
          }}
          variant="filled"
          severity="info"
          onClose={() => setAlertVisible(false)}
        >
          {alertMessage}
        </Alert>
      )}

      <VersatileAlertDialog
        open={versatileAlertOpen}
        onClose={handleVersatileAlertClose}
        onTryAgain={handleVersatileAlertClose}
      />
    </div>
  );
};

export default FormSummaryGeneration;
